import React from "react";
import axios from 'axios';
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Table,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row, CardBody, CardTitle, Col,
  FormGroup,
  Input,
  Button,
  Form,
  Modal,
} from "reactstrap";
import classnames from "classnames";
// core components
import Dash_header from "components/Headers/dash_header.jsx";
import Pagination from "react-js-pagination";
import { server_config } from 'const';

class Dashboard extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    //visitor data
    this.onChangevname = this.onChangevname.bind(this);
    this.onChangecontact = this.onChangecontact.bind(this);
    this.onChangeReason = this.onChangeReason.bind(this);
    this.onChangePassnum = this.onChangePassnum.bind(this);
    this.onChangeDept = this.onChangeDept.bind(this);
    this.onChangeOfficer = this.onChangeOfficer.bind(this);
    this.onChangetimein = this.onChangetimein.bind(this);
    this.onChangeRemark = this.onChangeRemark.bind(this);

    //modal 
    this.edittimeout = this.edittimeout.bind(this);
    this.onChangeRemark1 = this.onChangeRemark1.bind(this);
    this.onChangetimeout = this.onChangetimeout.bind(this);

    this.handleValidation = this.handleValidation.bind(this);

    this.state = { 
      token: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "",
      u_id: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.id : "0",
      error: null,
      isLoaded: false,
      t_stock: '',
      total_premises_visitor: '',
      t_dept: '',
      t_officer: '',
      items: [],
      tabs: 1,
      //officer list
      officers: [],
      officer_id: "",
      officerDropdown: [],
      //dept list
      depts: [],
      dept_id: "",
      DeptDropdown: [],
      v_name: "",
      contact: "",
      reason: "",
      time_in: "",
      pass_num: "",
      remark: "",
      // modal
      remark1: "",
      visitor_id: 0,
      time_out: "",
      errors: {},
      is_add_click: 0,
      is_edit_click: 0,
      activePage: 1
    };
  }

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!fields["v_name"]) {
      formIsValid = false;
      errors["vname"] = "Please enter visitor name.";
    }

    if (!fields["contact"]) {
      formIsValid = false;
      errors["vcontact"] = "Please enter visitor contact.";
    }

    if (!fields["reason"]) {
      formIsValid = false;
      errors["reasonto_meet"] = "Please enter reason to meet.";
    }

    if (!fields["time_in"]) {
      formIsValid = false;
      errors["intime"] = "Please enter time-in.";
    }

    if (!fields["pass_num"]) {
      formIsValid = false;
      errors["vcard"] = "Please enter pass/card number.";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  handleupdateValidation() {
    let errors = {};
    let formIsValid = true;
    if (this.state.time_out.trim()==='') {
      formIsValid = false;
      errors["outtime"] = "Please enter time-out.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    if (this._isMounted) {
      this.setState({
        [state]: index
      });
    }
  };

  checkValidation() {
    setTimeout(function () { //Start the timer
      if (this.state.is_add_click === 1) {
        // console.log(this.state.is_add_click);
        this.handleValidation();
      }
    }.bind(this), 300)
  }

  onChangetimeout(e) {
    if (this._isMounted) {
      this.setState({
        time_out: e.target.value,
      });
      setTimeout(function () { //Start the timer
        if (this.state.is_edit_click === 1) {
          this.handleupdateValidation();
        }
      }.bind(this), 300)
    }
  }
  //form data
  onChangeOfficer(e) {
    if (this._isMounted) {
      this.setState({
        officer_id: e.target.value,
      });
    }
  }
  onChangeDept(e) {
    if (this._isMounted) {
      this.setState({
        dept_id: e.target.value,
      });
    }
  }
  onChangevname(e) {
    if (this._isMounted) {
      this.setState({
        v_name: e.target.value,
      });
      this.checkValidation();
    }
  }
  onChangecontact(e) {
    if (this._isMounted) {
      this.setState({
        contact: e.target.value,
      });
      this.checkValidation();
    }
  }

  onChangeReason(e) {
    if (this._isMounted) {
      this.setState({
        reason: e.target.value,
      });
      this.checkValidation();
    }
  }
  onChangetimein(e) {
    if (this._isMounted) {
      this.setState({
        time_in: e.target.value,
      });
      this.checkValidation();
    }
  }
  onChangePassnum(e) {
    if (this._isMounted) {
      this.setState({
        pass_num: e.target.value,
      });
      this.checkValidation();
    }
  }
  onChangeRemark(e) {
    if (this._isMounted) {
      this.setState({
        remark: e.target.value,
      });
    }
  }
  //modal
  //open time out modal
  edittimeout(e) { //open Customer 
    let errors = {};
    this.setState({
      modal: !this.state.modal,
      errors: errors
    });
    // console.log(e.target.getAttribute("data-rmk"));
    if (this._isMounted) {
      this.setState({
        visitor_id: e.target.getAttribute("data-index"),
        remark1: e.target.getAttribute("data-rmk"),
        time_out: e.target.getAttribute("data-timeout"),
      });
    }
  }
  onChangeRemark1(e) {
    if (this._isMounted) {
      this.setState({
        remark1: e.target.value,
      });
    }
  }
  
  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    this.getVisitorList(1);
  }
  
  getVisitorList(pageNumber) {
    const apiData = {
      page: pageNumber
    };
    axios.post(server_config.API_BASE_URL+"visitor_list", apiData, { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (result.data.status === 1) {
          if (this._isMounted) {
            this.setState({
              isLoaded: true,
              items: result.data.visitor_list.data,
              t_stock: result.data.visitor_list.total,
              total_premises_visitor: result.data.total_premises_visitor
            });
          }
        } else {
          if (this._isMounted) {
            this.setState({
              isLoaded: true,
              items: [],
              t_stock: 0,
              total_premises_visitor : 0
            });
          }
        }
      }).catch(function (error) {
        //console.log(error);
      });
  }

  // api data response
  componentDidMount() {
    this._isMounted = true;
    //For Visitor List
    this.getVisitorList(1);

    //Officer List
    axios.post(server_config.API_BASE_URL+"officer_list", [], { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (result.data.status === 1) {
          if (this._isMounted) {
            this.setState({
              officers: result.data.officer_list,
              officer_id: result.data.officer_list[0].id,
              t_officer: result.data.total_officer,
            });
          }
          result.data.officer_list.forEach(officer => {
            if (this._isMounted) {
              this.setState({
                officerDropdown: this.state.officerDropdown.concat([{ o_id: officer.id, o_name: officer.officer_name }])
              });
            }
          });
        } else {
          if (this._isMounted) {
            this.setState({
              officers: [],
              officer_id: 0
            });
          }
        }
      }).catch(function (error) {
        //console.log(error);
      });

    //Department List
    axios.post(server_config.API_BASE_URL + "department_list", [], { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (result.data.status === 1) {
          if (this._isMounted) {
            this.setState({
              depts: result.data.department_list,
              dept_id: result.data.department_list[0].department_id,
              t_dept: result.data.total_department
            });
          }
          result.data.department_list.forEach(dept => {
            if (this._isMounted) {
              this.setState({
                DeptDropdown: this.state.DeptDropdown.concat([{ dept_id: dept.department_id, dept_name: dept.department_name }])
              });
            }
          });
        } else {
          if (this._isMounted) {
            this.setState({
              depts: [],
              dept_id: 0
            });
          }
        }
      }).catch(function (error) {
        //console.log(error);
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  //form data submit
  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({
      is_add_click: 1
    });
    if (this.handleValidation()) {
      const visitorData = {
        visitor_name: this.state.v_name,
        contact_number: this.state.contact,
        user_id: this.state.officer_id,
        department_id: this.state.dept_id,
        reason_to_meet: this.state.reason,
        time_in: this.state.time_in,
        card_number: this.state.pass_num,
        remark: this.state.remark
      };
      axios.post(server_config.API_BASE_URL + "add_visitor", visitorData, { headers: { "Authorization": "Bearer " + this.state.token } })
        .then(result => {
          if (result.data.status === 1) {
            alert("Visitor added SuccessFully");
            window.location.reload();
          } else {
            alert(result.data.msg);
          }
        }).catch(function (error) {
          alert(error);
        });
    } else {
    }
  };
  // time out modal data update
  handleUpdate = evt => {
    evt.preventDefault();
    this.setState({
      is_edit_click: 1
    });
    if (this.handleupdateValidation()) {
      const timeoutData = {
        time_out: this.state.time_out,
        remark1: this.state.remark1,
        visitor_id: this.state.visitor_id,
      };
      axios.post(server_config.API_BASE_URL + "update_visitor", timeoutData, { headers: { "Authorization": "Bearer " + this.state.token } })
        .then(result => {
          console.log(result);
          if (result.data.status === 1) {
            alert("Time Updated SuccessFully");
            window.location.reload();
          } else {
            alert(result.data.msg);
          }
        }).catch(function (error) {
          alert(error);
        });
    } else {
     // alert('test');
     }
  };

  render() {
    const { items, t_stock, total_premises_visitor, t_dept, t_officer } = this.state;
    return (
      <>
        <Dash_header />
        <Container className="" fluid>
          <Row>
            <Col sm="6">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Total Visitor inside premise
                          </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {total_premises_visitor}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="fas fa-users" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Total Visitor
                          </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {t_stock}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="fas fa-users" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="pt-2" style={{ marginBottom: 100 }}>
            <Col lg="12" md="12" className="p-0">
              <div className="nav-wrapper">
                <Nav
                  className="nav nav-pills"
                  id="tabs-icons-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.tabs === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.tabs === 1
                      })}
                      onClick={e => this.toggleNavs(e, "tabs", 1)}
                      href="#pablo"
                      role="tab"
                    >
                      Visitor Entry
              </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.tabs === 2
                      })}
                      onClick={e => this.toggleNavs(e, "tabs", 2)}
                      href="#pablo"
                      role="tab"
                    >
                      Visitor List
              </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <Card className="shadow">
                <CardBody>
                  <TabContent activeTab={"tabs" + this.state.tabs}>
                    <TabPane tabId="tabs1">
                      <form>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-vname">
                                Visitor Name<span className="text-danger">*</span>
                              </label>
                              <Input ref="vname" className="form-control-alternative" id="input-vname" placeholder="Visitor Name" type="Text" onChange={this.onChangevname} />
                              <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["vname"]}</span>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-vphone">
                                Contact Number<span className="text-danger">*</span>
                              </label>
                              <Input ref="vcontact" className="form-control-alternative" id="input-vphone" placeholder="Contact Number" type="Text" onChange={this.onChangecontact} />
                              <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["vcontact"]}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-vname">
                                Department
                              </label>
                              <select className="form-control form-control-alternative" id="input-customer" onChange={this.onChangeDept}>
                                {this.state.DeptDropdown.map((deptlist, idx) => (
                                  <option value={deptlist.dept_id} key={deptlist.dept_id}>{deptlist.dept_name}</option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-meet">
                                Whom to Meet
                              </label>
                              <select className="form-control form-control-alternative" id="input-meet" onChange={this.onChangeOfficer}>
                                {this.state.officerDropdown.map((offlist, idx) => (
                                  <option value={offlist.o_id} key={offlist.o_id}>{offlist.o_name}</option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-reason">
                                Reason to Meet<span className="text-danger">*</span>
                              </label>
                              <Input ref="reasonto_meet" className="form-control-alternative" id="input-reason" placeholder="Reason to Meet" type="Text" onChange={this.onChangeReason} />
                              <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["reasonto_meet"]}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-time">
                                Time in<span className="text-danger">*</span>
                              </label>
                              <Input ref="intime" className="form-control-alternative" id="input-time" placeholder="Time in" type="Time" onChange={this.onChangetimein} />
                              <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["intime"]}</span>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-pass">
                                Pass/Card Number<span className="text-danger">*</span>
                              </label>
                              <Input ref="vcard" className="form-control-alternative" id="input-pass" placeholder="Pass/Card Number" type="Text" onChange={this.onChangePassnum} />
                              <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["vcard"]}</span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-rmk">
                                Remark
                                  </label>
                              <Input className="form-control-alternative" id="input-rmk" placeholder="Remark" type="Text" onChange={this.onChangeRemark} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" className="text-center">
                            <Button className="my-4" color="danger" type="button" onClick={this.handleSubmit}>
                              Submit
                                </Button>
                          </Col>
                        </Row>
                      </form>
                    </TabPane>
                    <TabPane tabId="tabs2">
                      <Table className="table table-bordered" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Visitor Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Department</th>
                            <th scope="col">Whom to Meet</th>
                            <th scope="col">Reason to meet</th>
                            <th scope="col">Time in</th>
                            <th scope="col">Time out</th>
                            <th scope="col">Pass/Card Number</th>
                            <th scope="col">Remark</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>
                        <tbody className={items.length}>
                          {items.length ? "" : <tr><td colSpan="8" >Data not found.</td></tr>}
                          {items.map(visitor_list => (
                            <tr key={visitor_list.log_id}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <span className="mb-0 text-sm">
                                    {visitor_list.visitor_name}
                                  </span>
                                </Media>
                              </th>
                              <td>{visitor_list.contact_number}</td>
                              <td>{visitor_list.department_name}</td>
                              <td>{visitor_list.officer_name}</td>
                              <td>{visitor_list.reason_to_meet}</td>
                              <td>{visitor_list.time_in}</td>
                              <td class="text-center">{(visitor_list.time_out.trim()==='')?<i class="fas fa-pen bg-success text-white edit_icon" key={visitor_list.log_id} data-index={visitor_list.log_id} data-rmk={visitor_list.remark} data-timeout={visitor_list.time_out} onClick={this.edittimeout}></i>:visitor_list.time_out} </td>
                              <td>{visitor_list.card_number}</td>
                              <td>{visitor_list.remark}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {(this.state.t_stock > 10) &&
                        <Row>
                          <Col lg="12" className="text-center">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.t_stock}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange.bind(this)}
                                  />                
                          </Col>
                        </Row>
                        }
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Container>
        <Modal isOpen={this.state.modal}>
          <Form>
            <div class="modal-header">
              <h3 className="modal-title">Update Time-out</h3>
              {/* <button onClick={this.addcustomer} className="fa fa-times"></button> */}
            </div>
            <div className="modal-body">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-timout">
                      Time Out
                    </label>
                    <Input ref="outtime" value={this.state.time_out} className="form-control-alternative" id="input-timout" type="Time" onChange={this.onChangetimeout} />
                    <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["outtime"]}</span>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-rmk">
                      Remark
                    </label>
                    <Input value={this.state.remark1} className="form-control-alternative" id="input-rmk" placeholder="Remark" type="Text" onChange={this.onChangeRemark1} />
                  </FormGroup>
                </Col>

              </Row>
            </div>
            <div className="modal-footer">
              {/* <input type="submit" value="Submit" color="primary" className="btn" /> */}
              <Button className="bg-success border-0 text-white" type="button" onClick={this.handleUpdate}>Update</Button>
              <Button color="secondary" onClick={this.edittimeout}>Close</Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
const styles = {
  input: {
    padding: 10,
  },
};
export default Dashboard;
