import Dashboard from "views/examples/Dashboard.jsx";
import Login from "views/examples/Login.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-compass-04 text-default",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  }
];
export default routes;
