import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import MobLayout from "components/Sidebar/Sidebar.jsx";
import Login from "./views/examples/Login.jsx";

import axios from "axios";
import $ from "jquery";
import { server_config } from './const';

class App extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this._loginUser = this._loginUser.bind(this);
    this._logoutUser = this._logoutUser.bind(this);
    this.state = {
      isLoggedIn: false,
      user: {}
    };
  }
  _loginUser = (email, password) => {
    $("#login-form button")
      .attr("disabled", "disabled")
      .html(
        '<i className="fa fa-spinner fa-spin fa-1x fa-fw"></i><span className="sr-only">Loading...</span>'
      );
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios.post(server_config.API_BASE_URL+"login", formData)
      .then(response => {
        return response;
      }).then(json => {
          if (json.data.status===1) {
            const { id, email, auth_token } = json.data.user_data;

            let userData = {
              id,
              email,
              auth_token,
              timestamp: new Date().toString()
            };
            let appState = {
              isLoggedIn: true,
              user: userData
            };
            // save app state with user date in local storage
            localStorage["appState"] = JSON.stringify(appState);
            if (this._isMounted) {
            this.setState({
              isLoggedIn: appState.isLoggedIn,
              user: appState.user
            });
          }
        } else alert(json.data.msg);

        $("#login-form button").removeAttr("disabled").html("Login");
      }).catch(error => {
        alert(error);
        $("#login-form button").removeAttr("disabled").html("Login");
      });
  };
  
  // logout code
  _logoutUser = () => {    
    axios.post(server_config.API_BASE_URL+"logout", [] , { headers: { "Authorization": "Bearer " + JSON.parse(localStorage["appState"]).user.auth_token } })
    .then(response => {
    return response;
    }).catch(error => {

    });
    let appState = {
      isLoggedIn: false,
      user: {}
    };
    // save app state with user date in local storage
    localStorage["appState"] = JSON.stringify(appState);
    this.setState(appState);

  };

  componentDidMount() {
    this._isMounted = true;
    let state = localStorage["appState"];
    if (state) {
      let AppState = JSON.parse(state);
      if (this._isMounted) {
      this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    if (!this.state.isLoggedIn && this.props.location.pathname !== "/auth/login") {
      this.props.history.push("/auth/login");
    }
    if (this.state.isLoggedIn && this.props.location.pathname === "/auth/login") {
      this.props.history.push("/admin/dashboard");
    }
    return (
      <Switch data="data">
        <div id="main">
          <Route
            exact
            path="/"
            render={props => (
              <AdminLayout
                {...props}
                logoutUser={this._logoutUser}
                user={this.state.user}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={props => (
              <MobLayout
                {...props}
                logoutUser={this._logoutUser}
                user={this.state.user}
              />
            )}
          />
          <Route
            path="/auth/login"
            render={props => <Login {...props} loginUser={this._loginUser} />}
          />
<Route path="/admin" render={props => <AdminLayout {...props} />} />
      
        </div>
      </Switch>
    );
  }
}

const AppContainer = withRouter(props => <App {...props} />);
// console.log(store.getState())
render(
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>,

  document.getElementById("root")
);
