/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Navbar,
  Nav,
  Container
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "",
      u_name: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.name : "Sales Person",
      users: []
    };
    this.onLogout = this.onLogout.bind(this);
  }
  onLogout() {
    let appState = {
      isLoggedIn: false,
      user: {}
    };
    // save app state with user date in local storage
    localStorage["appState"] = JSON.stringify(appState);
    this.setState(appState);
    window.location.href = "/auth/login";
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <label className="brandtext">
            {/* {this.props.brandText} */}
            </label>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <span className="avatar-sm rounded-circle" style={{ color: "#000" }}>
                <i title="Logout" className="ni ni-button-power logouticon" onClick={this.onLogout}></i>
              </span>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
